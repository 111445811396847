define("ember-composable-helpers/helpers/without", ["exports", "@ember/component/helper", "@ember/array", "ember-composable-helpers/utils/includes"], function (_exports, _helper, _array, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.without = without;
  function contains(needle, haystack) {
    return (0, _includes.default)((0, _array.A)(haystack), needle);
  }
  function without(needle, haystack) {
    if (!(0, _array.isArray)(haystack)) {
      return false;
    }
    if ((0, _array.isArray)(needle) && needle.length) {
      return haystack.reduce((acc, val) => {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }
    return (0, _array.A)(haystack).without(needle);
  }
  var _default = (0, _helper.helper)(function ([needle, haystack]) {
    return without(needle, haystack);
  });
  _exports.default = _default;
});