define("dcp-ember-metrics/metrics-adapters/base", ["exports", "@ember/debug", "@ember/object/internals", "@ember/utils"], function (_exports, _debug, _internals, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function makeToString(ret) {
    return () => ret;
  }
  class BaseAdapter {
    constructor(config) {
      _defineProperty(this, "metrics", null);
      _defineProperty(this, "config", null);
      this.config = config;
    }
    install() {
      (true && !(false) && (0, _debug.assert)(`[dcp-ember-metrics] ${this.toString()} must implement the install hook!`));
    }
    uninstall() {
      (true && !(false) && (0, _debug.assert)(`[dcp-ember-metrics] ${this.toString()} must implement the uninstall hook!`));
    }
    toString() {
      const hasToStringExtension = (0, _utils.typeOf)(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `dcp-ember-metrics@metrics-adapter:${extension}:${(0, _internals.guidFor)(this)}`;
      this.toString = makeToString(ret);
      return ret;
    }
    identify() {}
    trackEvent() {}
    trackPage() {}
    alias() {}
  }
  _exports.default = BaseAdapter;
  _defineProperty(BaseAdapter, "supportsFastBoot", false);
});