define("dcp-ember-metrics/-private/utils/remove-from-dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = removeFromDOM;
  /**
   * Remove scripts defined by selectors from DOM.
   *
   * @param selectors One or more DOM selectors to match against.
   */
  function removeFromDOM(selectors) {
    document.querySelectorAll(selectors).forEach(el => {
      el.parentElement?.removeChild(el);
    });
  }
});