define("dcp-ember-metrics/metrics-adapters/matomo-tag-manager", ["exports", "@ember/debug", "dcp-ember-metrics/-private/utils/remove-from-dom", "dcp-ember-metrics/metrics-adapters/base"], function (_exports, _debug, _removeFromDom, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MatomoTagManager extends _base.default {
    toStringExtension() {
      return 'MatomoTagManager';
    }
    install() {
      const {
        matomoUrl,
        containerId
      } = this.config;
      (true && !(matomoUrl && containerId) && (0, _debug.assert)(`[dcp-ember-metrics] You must pass a \`matomoUrl\` and a \`containerId\` to the ${this.toString()} adapter`, matomoUrl && containerId)); // this._injectScript(matomoUrl, containerId);
    }

    // prettier-ignore
    _injectScript(matomoUrl, containerId) {
      window._mtm = window._mtm || [];
      window._mtm.push({
        'mtm.startTime': new Date().getTime(),
        'event': 'mtm.Start'
      });
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.src = `https://${matomoUrl}/js/container_${containerId}.js`;
      s.parentNode.insertBefore(g, s);
      g.id = 'matomo-tag-manager';
    }
    identify(options = {}) {
      window._mtm.push(['setUserId', options.userId]);
    }
    trackEvent(options = {}) {
      if (typeof _paq !== 'undefined') {
        _paq.push(['trackEvent', options.category, options.action, options.name, options.value]);
      }
    }
    trackPage(options = {}) {
      if (typeof _paq !== 'undefined') {
        _paq.push(['setCustomUrl', options.page]);
        _paq.push(['trackPageView', options.title]);
      }
    }
    uninstall() {
      (0, _removeFromDom.default)(`script[id="matomo-tag-manager"]`);
      delete window._mtm;
    }
  }
  _exports.default = MatomoTagManager;
});