define("ember-composable-helpers/helpers/reduce", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reduce = reduce;
  function reduce([callback, initialValue, array]) {
    if ((0, _utils.isEmpty)(callback)) {
      return [];
    }
    return array.reduce(callback, initialValue);
  }
  var _default = (0, _helper.helper)(reduce);
  _exports.default = _default;
});