define("ember-concurrency-retryable/index", ["exports", "ember-concurrency-retryable/retryable", "ember-concurrency-retryable/policies/base", "ember-concurrency-retryable/policies/delay", "ember-concurrency-retryable/policies/exponential-backoff", "ember-concurrency-retryable/-private/yieldables", "ember-concurrency"], function (_exports, _retryable, _base, _delay, _exponentialBackoff, _yieldables, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "DelayPolicy", {
    enumerable: true,
    get: function () {
      return _delay.default;
    }
  });
  Object.defineProperty(_exports, "ExponentialBackoffPolicy", {
    enumerable: true,
    get: function () {
      return _exponentialBackoff.default;
    }
  });
  Object.defineProperty(_exports, "Policy", {
    enumerable: true,
    get: function () {
      return _base.default;
    }
  });
  _exports.disableTaskRetries = disableTaskRetries;
  _exports.enableTaskRetries = enableTaskRetries;
  _exports.isEnabled = isEnabled;
  Object.defineProperty(_exports, "retriedSignal", {
    enumerable: true,
    get: function () {
      return _yieldables.retriedSignal;
    }
  });
  Object.defineProperty(_exports, "retryable", {
    enumerable: true,
    get: function () {
      return _retryable.default;
    }
  });
  let ENABLED = true;
  function isEnabled() {
    return ENABLED;
  }
  function disableTaskRetries() {
    ENABLED = false;
  }
  function enableTaskRetries() {
    ENABLED = true;
  }
  (0, _emberConcurrency.registerModifier)('retryable', _retryable.default);

  // Legacy TaskProperty
  _emberConcurrency.TaskProperty.prototype.retryable = function (retryPolicy) {
    let taskFn = this.taskFn;
    let setTaskDefinition = fn => {
      this.taskFn = fn;
    };
    let taskFactory = {
      get taskDefinition() {
        return taskFn;
      },
      setTaskDefinition
    };
    (0, _retryable.default)(taskFactory, retryPolicy);
    return this;
  };
});