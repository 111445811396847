define("ember-cli-foundation-6-sass/components/zf-reveal", ["exports", "@ember/component", "@nycplanning/ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _component, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_zfWidget.default, {
    /** @member Class names */
    classNames: ['reveal'],
    /** @member Attribute bindings */
    attributeBindings: ['data-reveal'],
    /** @member Makes the data attribute binding appear */
    'data-reveal': ' ',
    'overlay': true,
    /** @member Foundation type */
    'zfType': 'Reveal',
    /** @member Foundation specific options */
    'zfOptions': ['showDelay', 'showDelay', 'closeOnClick', 'closeOnEsc', 'multipleOpened', 'vOffset', 'hOffset', 'fullScreen', 'btmOffsetPct', 'overlay', 'resetOnClose', 'deepLink'],
    /**
     * Handle any configuration after the widget has been inserted.
     */
    handleInsert() {
      this.$().css("outline", "none");
    }
  });
  _exports.default = _default;
});