define("ember-composable-helpers/helpers/filter-by", ["exports", "@ember/component/helper", "@ember/array", "@ember/utils", "@ember/object", "ember-composable-helpers/utils/is-equal"], function (_exports, _helper, _array, _utils, _object, _isEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterBy = filterBy;
  function filterBy([byPath, value, array]) {
    if (!(0, _array.isArray)(array) && (0, _array.isArray)(value)) {
      array = value;
      value = undefined;
    }
    if ((0, _utils.isEmpty)(byPath) || (0, _utils.isEmpty)(array)) {
      return [];
    }
    let filterFn;
    if ((0, _utils.isPresent)(value)) {
      if (typeof value === 'function') {
        filterFn = item => value((0, _object.get)(item, byPath));
      } else {
        filterFn = item => (0, _isEqual.default)((0, _object.get)(item, byPath), value);
      }
    } else {
      filterFn = item => !!(0, _object.get)(item, byPath);
    }
    return array.filter(filterFn);
  }
  var _default = (0, _helper.helper)(filterBy);
  _exports.default = _default;
});