define("ember-composable-helpers/helpers/drop", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.drop = drop;
  function drop([dropAmount, array]) {
    if (!array) {
      array = [];
    }
    return array.slice(dropAmount);
  }
  var _default = (0, _helper.helper)(drop);
  _exports.default = _default;
});