define("ember-cli-foundation-6-sass/components/zf-tooltip", ["exports", "@ember/component", "@nycplanning/ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _component, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_zfWidget.default, {
    /** @member tag type */
    tagName: 'span',
    /** @member Class names */
    classNames: ['has-tip'],
    /** @member Attribute bindings */
    attributeBindings: ['data-tooltip', 'aria-haspopup', 'data-disable-hover', 'title'],
    /** @member Makes the data attribute binding appear */
    'data-tooltip': ' ',
    /** @member ARIA popup flag */
    'aria-haspopup': 'true',
    /** @member Enable hover */
    'data-disable-hover': 'false',
    /** @member Tooltip text */
    'title': '',
    /** @member Foundation type */
    'zfType': 'Tooltip',
    /** @member Foundation specific options */
    'zfOptions': ['hoverDelay', 'fadeInDuration', 'fadeOutDuration', 'disableHover', 'templateClasses', 'tooltipClass', 'triggerClass', 'showOn', 'zf-template', 'tipText', 'clickOpen', 'positionClass', 'vOffset', 'hOffset']
  });
  _exports.default = _default;
});