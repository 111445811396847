define("ember-concurrency-retryable/-private/yieldables", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.retriedSignal = _exports.getTaskInstance = _exports.RETRYABLE_SYMBOL = void 0;
  const RETRYABLE_SYMBOL = '__ec_retryable_instance';
  _exports.RETRYABLE_SYMBOL = RETRYABLE_SYMBOL;
  class RetriedSignalYieldable extends _emberConcurrency.Yieldable {
    onYield(state) {
      const taskInstance = state.getTaskInstance();
      const retryableInstance = taskInstance[RETRYABLE_SYMBOL];
      if (retryableInstance && retryableInstance.retryCount > 0) {
        retryableInstance._didRetry();
      }
      state.next();
    }
  }
  class GetTaskInstanceYieldable extends _emberConcurrency.Yieldable {
    onYield(state) {
      state.next(state.getTaskInstance());
      return () => {};
    }
  }
  const getTaskInstance = new GetTaskInstanceYieldable();
  _exports.getTaskInstance = getTaskInstance;
  const retriedSignal = new RetriedSignalYieldable();
  _exports.retriedSignal = retriedSignal;
});