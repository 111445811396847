define("ember-concurrency-retryable/retryable", ["exports", "ember-concurrency-retryable/-private/retryable-task-instance"], function (_exports, _retryableTaskInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = retryable;
  function retryable(taskFactory, retryPolicy) {
    const baseTaskFn = taskFactory.taskDefinition;
    const wrappedTaskFn = function* (...args) {
      const instance = new _retryableTaskInstance.default({
        policy: retryPolicy,
        context: this,
        fn: baseTaskFn,
        args: args
      });
      return yield* instance.run();
    };
    taskFactory.setTaskDefinition(wrappedTaskFn);
    return taskFactory;
  }
});