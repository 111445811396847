define("ember-g-recaptcha/components/g-recaptcha", ["exports", "@ember/component", "@ember/object/computed", "@ember/utils", "@ember/polyfills", "ember-g-recaptcha/configuration"], function (_exports, _component, _computed, _utils, _polyfills, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: ['g-recaptcha'],
    sitekey: _configuration.default.siteKey,
    tabindex: (0, _computed.alias)('tabIndex'),
    renderReCaptcha() {
      let properties = this.getProperties('sitekey', 'theme', 'type', 'size', 'tabindex', 'hl', 'badge');
      let parameters = (0, _polyfills.assign)(properties, {
        callback: this.get('successCallback').bind(this),
        'expired-callback': this.get('expiredCallback').bind(this)
      });
      let widgetId = window.grecaptcha.render(this.get('element'), parameters);
      this.set('widgetId', widgetId);
      this.set('ref', this);
      this.renderCallback();
    },
    resetReCaptcha() {
      if ((0, _utils.isPresent)(this.get('widgetId'))) {
        window.grecaptcha.reset(this.get('widgetId'));
      }
    },
    renderCallback() {
      let action = this.get('onRender');
      if ((0, _utils.isPresent)(action)) {
        action();
      }
    },
    successCallback(reCaptchaResponse) {
      let action = this.get('onSuccess');
      if ((0, _utils.isPresent)(action)) {
        action(reCaptchaResponse);
      }
    },
    expiredCallback() {
      let action = this.get('onExpired');
      if ((0, _utils.isPresent)(action)) {
        action();
      } else {
        this.resetReCaptcha();
      }
    },
    appendScript(src) {
      let scriptTag = document.createElement('script');
      scriptTag.src = src;
      document.body.appendChild(scriptTag);
    },
    // Lifecycle Hooks

    didInsertElement() {
      this._super(...arguments);
      window.__ember_g_recaptcha_onload_callback = () => {
        this.renderReCaptcha();
      };
      let baseUrl = _configuration.default.jsUrl || 'https://www.google.com/recaptcha/api.js?render=explicit';
      this.appendScript(`${baseUrl}&onload=__ember_g_recaptcha_onload_callback`);
    }
  });
  _exports.default = _default;
});