define("ember-cli-foundation-6-sass/components/zf-slider", ["exports", "@ember/component", "@nycplanning/ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _component, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_zfWidget.default, {
    /** @member Class names */
    classNames: ['slider'],
    /** @member Attribute bindings */
    attributeBindings: ['data-slider', 'data-initial-start:inital-start', 'data-end:end-value'],
    /** @member Makes the data attribute binding appear */
    'data-slider': ' ',
    /** @member Start value of slider */
    'initial-start': 50,
    /** @member End value of slider */
    'end-value': 200,
    /** @member Foundation type */
    'zfType': 'Slider',
    /** @member Foundation specific options */
    'zfOptions': ['start', 'end', 'step', 'initialStart', 'initialEnd', 'binding', 'clickSelect', 'vertical', 'draggable', 'disabled', 'doubleSided', 'decimal', 'moveTime', 'disabledClass']
  });
  _exports.default = _default;
});